// import { defineNuxtPlugin } from '#app'
import ahoy from 'ahoy.js'
import { useUserStore } from '~/store/user.js';

export default defineNuxtPlugin(nuxtApp => {
  const runtimeConfig = useRuntimeConfig();
  const store = useUserStore()

  ahoy.configure({
    visitsUrl: `${runtimeConfig.public.baseApiUrl}/api/s/visits.json`,
    eventsUrl: `${runtimeConfig.public.baseApiUrl}/api/s/events.json`,
    platform: "Web",
    useBeacon: true,
    startOnReady: true,
    trackVisits: true,
    withCredentials: false,
    headers: {
      'Content-Type': 'application/json',
      "Authorization": store.token
    },
    // visitParams: {},
    visitDuration: 2 * 60, // 2 hours
    // visitorDuration: 2 * 365 * 24 * 60 // 2 years
  });

  // Make sure that pageviews are captured with each route change
  const router = useRouter();
  router.afterEach((to) => {
    // if (!import.meta.env.SSR && !_to.path.startsWith('/meus_eventos')) {
    ahoy.trackView()
    // posthog.capture('$pageview', {
    //   current_url: to.fullPath
    // });
  });

  return {
    provide: {
      ahoy: () => ahoy
    }
  }
})
