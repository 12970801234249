
export default defineNuxtRouteMiddleware((to, from) => {
  const runtimeConfig = useRuntimeConfig()
  const { adminUrl } = runtimeConfig.public
  // console.log('opa => ', to, useNuxtApp())
  // console.log('opa => ', to.fullPath)

  if(to.fullPath.startsWith('/meus_eventos')) {
    return navigateTo(adminUrl + to.fullPath, { external: true })
  }

  // if (isAuthenticated() === false) {
  //
  // }
})
