import instance from '../base.service';

class ManagerProducerService {
  async find(slug) {
    return await instance.get(`api/manager/producers/${slug}.json`)
  }

  // async create(data) {
  //   return await instance.post('api/producers.json', { producer: data })
  // }

  async update(id, params) {
    return await instance.put(`api/manager/producers/${id}.json`, { producer: params })
  }


  // async adminIndex() {
  //   return await instance.get('api/producers/admin_index.json')
  // }

  // async create(data) {
  //   return await instance.post('api/producers.json', { producer: data })
  // }
}

export default new ManagerProducerService();
