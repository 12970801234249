<template>
  <div>
    <VitePwaManifest />
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
useSchemaOrg([
  defineOrganization({
    name: 'pixta.me',
    aggregateRating: {
      ratingValue: '5',
      ratingCount: '4200'
    },
    foundingDate: '2021',
    foundingLocation: 'Curitiba, PR',
    keywords: 'ingressos, eventos, curitiba, pix, pixta, pixtame, cartao',
    logo: '/img/icon-no-bg.png',
    sameAs: [
      'https://twitter.com/pixta_me',
      'https://instagram.com/pixta.me',
    ],
    url: 'https://pixta.me'
  })
])

useHead({
  titleTemplate: (title) => !title ? 'PIXTA.me' : `${title} | PIXTA.me`,
  description: 'Compre e venda ingressos para seus eventos, por pix e cartão, com agilidade e segurança', // max 155 / 200
  htmlAttrs: {
    lang: 'pt-BR'
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes' }
  ],
  bodyAttrs: {
    // class: 'dark'
  },
  link: [
    // { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    // { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;900&display=swap' },
    // { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    // { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: '' }
  ],
  script: [
    // { src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAixflCc-p8uJO-eGleMs4QPBpFcIptBGc&libraries=places' }
  ]
})
</script>
