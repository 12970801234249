import { default as arquivoIaCPoOWTztMeta } from "/vercel/path0/pages/arquivo.vue?macro=true";
import { default as extrato2IT9N36iPtMeta } from "/vercel/path0/pages/carteira/extrato.vue?macro=true";
import { default as indexP8SdDIXJZcMeta } from "/vercel/path0/pages/carteira/index.vue?macro=true";
import { default as carteiraBJsJpw7M5dMeta } from "/vercel/path0/pages/carteira.vue?macro=true";
import { default as indextvhAZ7Kq3ZMeta } from "/vercel/path0/pages/central-de-ajuda/index.vue?macro=true";
import { default as cardv6yDC6gjDNMeta } from "/vercel/path0/pages/checkout/[orderId]/card.vue?macro=true";
import { default as pixgcOkpDyM3aMeta } from "/vercel/path0/pages/checkout/[orderId]/pix.vue?macro=true";
import { default as como_45funcionaMCnCkGn0OYMeta } from "/vercel/path0/pages/como-funciona.vue?macro=true";
import { default as contaRNCjikNgMWMeta } from "/vercel/path0/pages/conta.vue?macro=true";
import { default as crie_45seu_45eventoU6FBsWx0yhMeta } from "/vercel/path0/pages/crie-seu-evento.vue?macro=true";
import { default as entrark4vXiRcpiKMeta } from "/vercel/path0/pages/entrar.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexCuWPBj39BKMeta } from "/vercel/path0/pages/ingressos/[id]/index.vue?macro=true";
import { default as transferirpUbwsrhAi1Meta } from "/vercel/path0/pages/ingressos/[id]/transferir.vue?macro=true";
import { default as trocar_45nomeIs3jHvMEAGMeta } from "/vercel/path0/pages/ingressos/[id]/trocar-nome.vue?macro=true";
import { default as indexNVa52wjbwoMeta } from "/vercel/path0/pages/ingressos/index.vue?macro=true";
import { default as my_accountR2VGeWjWHNMeta } from "/vercel/path0/pages/my_account.vue?macro=true";
import { default as indexH5AaUUeSy6Meta } from "/vercel/path0/pages/onboarding/index.vue?macro=true";
import { default as cancelarxkE6LxYVMYMeta } from "/vercel/path0/pages/orders/[id]/cancelar.vue?macro=true";
import { default as indexMzbDRHMBrDMeta } from "/vercel/path0/pages/orders/[id]/index.vue?macro=true";
import { default as indexan9ZrZAr6jMeta } from "/vercel/path0/pages/orders/index.vue?macro=true";
import { default as editmoi9t0929fMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/edit.vue?macro=true";
import { default as index6fcOqsrXuFMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/index.vue?macro=true";
import { default as send7wDVBgKFIlMeta } from "/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/send.vue?macro=true";
import { default as indexljmFmtnJqIMeta } from "/vercel/path0/pages/p/[slug]/campaigns/index.vue?macro=true";
import { default as new9Bb5FdVE4MMeta } from "/vercel/path0/pages/p/[slug]/campaigns/new.vue?macro=true";
import { default as campaignsDfCQHEsYkPMeta } from "/vercel/path0/pages/p/[slug]/campaigns.vue?macro=true";
import { default as editXIVbAO1LNGMeta } from "/vercel/path0/pages/p/[slug]/edit.vue?macro=true";
import { default as editCxWmwn5ZkuMeta } from "/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/edit.vue?macro=true";
import { default as indexsL6R3wPZORMeta } from "/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/index.vue?macro=true";
import { default as indexDyHHDt0pBNMeta } from "/vercel/path0/pages/p/[slug]/guestlists/index.vue?macro=true";
import { default as new3LWjwTB37pMeta } from "/vercel/path0/pages/p/[slug]/guestlists/new.vue?macro=true";
import { default as guestlistsfTSphu4R1VMeta } from "/vercel/path0/pages/p/[slug]/guestlists.vue?macro=true";
import { default as indextg33DI1USAMeta } from "/vercel/path0/pages/p/[slug]/index.vue?macro=true";
import { default as _91guestlistId_93dnnBRI1DKFMeta } from "/vercel/path0/pages/p/[slug]/listas/[guestlistId].vue?macro=true";
import { default as galerial57XEToYTFMeta } from "/vercel/path0/pages/parceiros/[slug]/galeria.vue?macro=true";
import { default as indexkccEgoEs10Meta } from "/vercel/path0/pages/parceiros/[slug]/index.vue?macro=true";
import { default as ranking24INbcWy9LMeta } from "/vercel/path0/pages/parceiros/[slug]/ranking.vue?macro=true";
import { default as _91slug_93GzY2HDfJMOMeta } from "/vercel/path0/pages/parceiros/[slug].vue?macro=true";
import { default as indexQpXVhpZ6xmMeta } from "/vercel/path0/pages/parceiros/index.vue?macro=true";
import { default as indexZETROZKIcTMeta } from "/vercel/path0/pages/producer_wizard/index.vue?macro=true";
import { default as new3aF4rqkogXMeta } from "/vercel/path0/pages/producer_wizard/new.vue?macro=true";
import { default as _91slug_93OsOWxVXvk9Meta } from "/vercel/path0/pages/producer_wizard/success/[slug].vue?macro=true";
import { default as resgatarTT0vypIzdFMeta } from "/vercel/path0/pages/resgatar.vue?macro=true";
import { default as indexRYqrGfppHXMeta } from "/vercel/path0/pages/u/[slug]/[productSlug]/index.vue?macro=true";
import { default as indexVeV4FOmvW8Meta } from "/vercel/path0/pages/u/[slug]/index.vue?macro=true";
export default [
  {
    name: arquivoIaCPoOWTztMeta?.name ?? "arquivo",
    path: arquivoIaCPoOWTztMeta?.path ?? "/arquivo",
    meta: arquivoIaCPoOWTztMeta || {},
    alias: arquivoIaCPoOWTztMeta?.alias || [],
    redirect: arquivoIaCPoOWTztMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/arquivo.vue").then(m => m.default || m)
  },
  {
    path: carteiraBJsJpw7M5dMeta?.path ?? "/carteira",
    children: [
  {
    name: extrato2IT9N36iPtMeta?.name ?? "carteira-extrato",
    path: extrato2IT9N36iPtMeta?.path ?? "extrato",
    meta: extrato2IT9N36iPtMeta || {},
    alias: extrato2IT9N36iPtMeta?.alias || [],
    redirect: extrato2IT9N36iPtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/carteira/extrato.vue").then(m => m.default || m)
  },
  {
    name: indexP8SdDIXJZcMeta?.name ?? "carteira",
    path: indexP8SdDIXJZcMeta?.path ?? "",
    meta: indexP8SdDIXJZcMeta || {},
    alias: indexP8SdDIXJZcMeta?.alias || [],
    redirect: indexP8SdDIXJZcMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/carteira/index.vue").then(m => m.default || m)
  }
],
    name: carteiraBJsJpw7M5dMeta?.name ?? undefined,
    meta: carteiraBJsJpw7M5dMeta || {},
    alias: carteiraBJsJpw7M5dMeta?.alias || [],
    redirect: carteiraBJsJpw7M5dMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/carteira.vue").then(m => m.default || m)
  },
  {
    name: indextvhAZ7Kq3ZMeta?.name ?? "central-de-ajuda",
    path: indextvhAZ7Kq3ZMeta?.path ?? "/central-de-ajuda",
    meta: indextvhAZ7Kq3ZMeta || {},
    alias: indextvhAZ7Kq3ZMeta?.alias || [],
    redirect: indextvhAZ7Kq3ZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/central-de-ajuda/index.vue").then(m => m.default || m)
  },
  {
    name: cardv6yDC6gjDNMeta?.name ?? "checkout-orderId-card",
    path: cardv6yDC6gjDNMeta?.path ?? "/checkout/:orderId()/card",
    meta: cardv6yDC6gjDNMeta || {},
    alias: cardv6yDC6gjDNMeta?.alias || [],
    redirect: cardv6yDC6gjDNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/checkout/[orderId]/card.vue").then(m => m.default || m)
  },
  {
    name: pixgcOkpDyM3aMeta?.name ?? "checkout-orderId-pix",
    path: pixgcOkpDyM3aMeta?.path ?? "/checkout/:orderId()/pix",
    meta: pixgcOkpDyM3aMeta || {},
    alias: pixgcOkpDyM3aMeta?.alias || [],
    redirect: pixgcOkpDyM3aMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/checkout/[orderId]/pix.vue").then(m => m.default || m)
  },
  {
    name: como_45funcionaMCnCkGn0OYMeta?.name ?? "como-funciona",
    path: como_45funcionaMCnCkGn0OYMeta?.path ?? "/como-funciona",
    meta: como_45funcionaMCnCkGn0OYMeta || {},
    alias: como_45funcionaMCnCkGn0OYMeta?.alias || [],
    redirect: como_45funcionaMCnCkGn0OYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/como-funciona.vue").then(m => m.default || m)
  },
  {
    name: contaRNCjikNgMWMeta?.name ?? "conta",
    path: contaRNCjikNgMWMeta?.path ?? "/conta",
    meta: contaRNCjikNgMWMeta || {},
    alias: contaRNCjikNgMWMeta?.alias || [],
    redirect: contaRNCjikNgMWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/conta.vue").then(m => m.default || m)
  },
  {
    name: crie_45seu_45eventoU6FBsWx0yhMeta?.name ?? "crie-seu-evento",
    path: crie_45seu_45eventoU6FBsWx0yhMeta?.path ?? "/crie-seu-evento",
    meta: crie_45seu_45eventoU6FBsWx0yhMeta || {},
    alias: crie_45seu_45eventoU6FBsWx0yhMeta?.alias || [],
    redirect: crie_45seu_45eventoU6FBsWx0yhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/crie-seu-evento.vue").then(m => m.default || m)
  },
  {
    name: entrark4vXiRcpiKMeta?.name ?? "entrar",
    path: entrark4vXiRcpiKMeta?.path ?? "/entrar",
    meta: entrark4vXiRcpiKMeta || {},
    alias: entrark4vXiRcpiKMeta?.alias || [],
    redirect: entrark4vXiRcpiKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/entrar.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexCuWPBj39BKMeta?.name ?? "ingressos-id",
    path: indexCuWPBj39BKMeta?.path ?? "/ingressos/:id()",
    meta: indexCuWPBj39BKMeta || {},
    alias: indexCuWPBj39BKMeta?.alias || [],
    redirect: indexCuWPBj39BKMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/ingressos/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: transferirpUbwsrhAi1Meta?.name ?? "ingressos-id-transferir",
    path: transferirpUbwsrhAi1Meta?.path ?? "/ingressos/:id()/transferir",
    meta: transferirpUbwsrhAi1Meta || {},
    alias: transferirpUbwsrhAi1Meta?.alias || [],
    redirect: transferirpUbwsrhAi1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/ingressos/[id]/transferir.vue").then(m => m.default || m)
  },
  {
    name: trocar_45nomeIs3jHvMEAGMeta?.name ?? "ingressos-id-trocar-nome",
    path: trocar_45nomeIs3jHvMEAGMeta?.path ?? "/ingressos/:id()/trocar-nome",
    meta: trocar_45nomeIs3jHvMEAGMeta || {},
    alias: trocar_45nomeIs3jHvMEAGMeta?.alias || [],
    redirect: trocar_45nomeIs3jHvMEAGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/ingressos/[id]/trocar-nome.vue").then(m => m.default || m)
  },
  {
    name: indexNVa52wjbwoMeta?.name ?? "ingressos",
    path: indexNVa52wjbwoMeta?.path ?? "/ingressos",
    meta: indexNVa52wjbwoMeta || {},
    alias: indexNVa52wjbwoMeta?.alias || [],
    redirect: indexNVa52wjbwoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/ingressos/index.vue").then(m => m.default || m)
  },
  {
    name: my_accountR2VGeWjWHNMeta?.name ?? "my_account",
    path: my_accountR2VGeWjWHNMeta?.path ?? "/my_account",
    meta: my_accountR2VGeWjWHNMeta || {},
    alias: my_accountR2VGeWjWHNMeta?.alias || [],
    redirect: my_accountR2VGeWjWHNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/my_account.vue").then(m => m.default || m)
  },
  {
    name: indexH5AaUUeSy6Meta?.name ?? "onboarding",
    path: indexH5AaUUeSy6Meta?.path ?? "/onboarding",
    meta: indexH5AaUUeSy6Meta || {},
    alias: indexH5AaUUeSy6Meta?.alias || [],
    redirect: indexH5AaUUeSy6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: cancelarxkE6LxYVMYMeta?.name ?? "orders-id-cancelar",
    path: cancelarxkE6LxYVMYMeta?.path ?? "/orders/:id()/cancelar",
    meta: cancelarxkE6LxYVMYMeta || {},
    alias: cancelarxkE6LxYVMYMeta?.alias || [],
    redirect: cancelarxkE6LxYVMYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/orders/[id]/cancelar.vue").then(m => m.default || m)
  },
  {
    name: indexMzbDRHMBrDMeta?.name ?? "orders-id",
    path: indexMzbDRHMBrDMeta?.path ?? "/orders/:id()",
    meta: indexMzbDRHMBrDMeta || {},
    alias: indexMzbDRHMBrDMeta?.alias || [],
    redirect: indexMzbDRHMBrDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexan9ZrZAr6jMeta?.name ?? "orders",
    path: indexan9ZrZAr6jMeta?.path ?? "/orders",
    meta: indexan9ZrZAr6jMeta || {},
    alias: indexan9ZrZAr6jMeta?.alias || [],
    redirect: indexan9ZrZAr6jMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    path: campaignsDfCQHEsYkPMeta?.path ?? "/p/:slug()/campaigns",
    children: [
  {
    name: editmoi9t0929fMeta?.name ?? "p-slug-campaigns-campaignId-edit",
    path: editmoi9t0929fMeta?.path ?? ":campaignId()/edit",
    meta: editmoi9t0929fMeta || {},
    alias: editmoi9t0929fMeta?.alias || [],
    redirect: editmoi9t0929fMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/edit.vue").then(m => m.default || m)
  },
  {
    name: index6fcOqsrXuFMeta?.name ?? "p-slug-campaigns-campaignId",
    path: index6fcOqsrXuFMeta?.path ?? ":campaignId()",
    meta: index6fcOqsrXuFMeta || {},
    alias: index6fcOqsrXuFMeta?.alias || [],
    redirect: index6fcOqsrXuFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/index.vue").then(m => m.default || m)
  },
  {
    name: send7wDVBgKFIlMeta?.name ?? "p-slug-campaigns-campaignId-send",
    path: send7wDVBgKFIlMeta?.path ?? ":campaignId()/send",
    meta: send7wDVBgKFIlMeta || {},
    alias: send7wDVBgKFIlMeta?.alias || [],
    redirect: send7wDVBgKFIlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/[campaignId]/send.vue").then(m => m.default || m)
  },
  {
    name: indexljmFmtnJqIMeta?.name ?? "p-slug-campaigns",
    path: indexljmFmtnJqIMeta?.path ?? "",
    meta: indexljmFmtnJqIMeta || {},
    alias: indexljmFmtnJqIMeta?.alias || [],
    redirect: indexljmFmtnJqIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: new9Bb5FdVE4MMeta?.name ?? "p-slug-campaigns-new",
    path: new9Bb5FdVE4MMeta?.path ?? "new",
    meta: new9Bb5FdVE4MMeta || {},
    alias: new9Bb5FdVE4MMeta?.alias || [],
    redirect: new9Bb5FdVE4MMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns/new.vue").then(m => m.default || m)
  }
],
    name: campaignsDfCQHEsYkPMeta?.name ?? undefined,
    meta: campaignsDfCQHEsYkPMeta || {},
    alias: campaignsDfCQHEsYkPMeta?.alias || [],
    redirect: campaignsDfCQHEsYkPMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/campaigns.vue").then(m => m.default || m)
  },
  {
    name: editXIVbAO1LNGMeta?.name ?? "p-slug-edit",
    path: editXIVbAO1LNGMeta?.path ?? "/p/:slug()/edit",
    meta: editXIVbAO1LNGMeta || {},
    alias: editXIVbAO1LNGMeta?.alias || [],
    redirect: editXIVbAO1LNGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    path: guestlistsfTSphu4R1VMeta?.path ?? "/p/:slug()/guestlists",
    children: [
  {
    name: editCxWmwn5ZkuMeta?.name ?? "p-slug-guestlists-guestlistId-edit",
    path: editCxWmwn5ZkuMeta?.path ?? ":guestlistId()/edit",
    meta: editCxWmwn5ZkuMeta || {},
    alias: editCxWmwn5ZkuMeta?.alias || [],
    redirect: editCxWmwn5ZkuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexsL6R3wPZORMeta?.name ?? "p-slug-guestlists-guestlistId",
    path: indexsL6R3wPZORMeta?.path ?? ":guestlistId()",
    meta: indexsL6R3wPZORMeta || {},
    alias: indexsL6R3wPZORMeta?.alias || [],
    redirect: indexsL6R3wPZORMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/[guestlistId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexDyHHDt0pBNMeta?.name ?? "p-slug-guestlists",
    path: indexDyHHDt0pBNMeta?.path ?? "",
    meta: indexDyHHDt0pBNMeta || {},
    alias: indexDyHHDt0pBNMeta?.alias || [],
    redirect: indexDyHHDt0pBNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/index.vue").then(m => m.default || m)
  },
  {
    name: new3LWjwTB37pMeta?.name ?? "p-slug-guestlists-new",
    path: new3LWjwTB37pMeta?.path ?? "new",
    meta: new3LWjwTB37pMeta || {},
    alias: new3LWjwTB37pMeta?.alias || [],
    redirect: new3LWjwTB37pMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists/new.vue").then(m => m.default || m)
  }
],
    name: guestlistsfTSphu4R1VMeta?.name ?? undefined,
    meta: guestlistsfTSphu4R1VMeta || {},
    alias: guestlistsfTSphu4R1VMeta?.alias || [],
    redirect: guestlistsfTSphu4R1VMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/guestlists.vue").then(m => m.default || m)
  },
  {
    name: indextg33DI1USAMeta?.name ?? "p-slug",
    path: indextg33DI1USAMeta?.path ?? "/p/:slug()",
    meta: indextg33DI1USAMeta || {},
    alias: indextg33DI1USAMeta?.alias || [],
    redirect: indextg33DI1USAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: _91guestlistId_93dnnBRI1DKFMeta?.name ?? "p-slug-listas-guestlistId",
    path: _91guestlistId_93dnnBRI1DKFMeta?.path ?? "/p/:slug()/listas/:guestlistId()",
    meta: _91guestlistId_93dnnBRI1DKFMeta || {},
    alias: _91guestlistId_93dnnBRI1DKFMeta?.alias || [],
    redirect: _91guestlistId_93dnnBRI1DKFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/p/[slug]/listas/[guestlistId].vue").then(m => m.default || m)
  },
  {
    path: _91slug_93GzY2HDfJMOMeta?.path ?? "/parceiros/:slug()",
    children: [
  {
    name: galerial57XEToYTFMeta?.name ?? "parceiros-slug-galeria",
    path: galerial57XEToYTFMeta?.path ?? "galeria",
    meta: galerial57XEToYTFMeta || {},
    alias: galerial57XEToYTFMeta?.alias || [],
    redirect: galerial57XEToYTFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/parceiros/[slug]/galeria.vue").then(m => m.default || m)
  },
  {
    name: indexkccEgoEs10Meta?.name ?? "parceiros-slug",
    path: indexkccEgoEs10Meta?.path ?? "",
    meta: indexkccEgoEs10Meta || {},
    alias: indexkccEgoEs10Meta?.alias || [],
    redirect: indexkccEgoEs10Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/parceiros/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: ranking24INbcWy9LMeta?.name ?? "parceiros-slug-ranking",
    path: ranking24INbcWy9LMeta?.path ?? "ranking",
    meta: ranking24INbcWy9LMeta || {},
    alias: ranking24INbcWy9LMeta?.alias || [],
    redirect: ranking24INbcWy9LMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/parceiros/[slug]/ranking.vue").then(m => m.default || m)
  }
],
    name: _91slug_93GzY2HDfJMOMeta?.name ?? undefined,
    meta: _91slug_93GzY2HDfJMOMeta || {},
    alias: _91slug_93GzY2HDfJMOMeta?.alias || [],
    redirect: _91slug_93GzY2HDfJMOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/parceiros/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexQpXVhpZ6xmMeta?.name ?? "parceiros",
    path: indexQpXVhpZ6xmMeta?.path ?? "/parceiros",
    meta: indexQpXVhpZ6xmMeta || {},
    alias: indexQpXVhpZ6xmMeta?.alias || [],
    redirect: indexQpXVhpZ6xmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/parceiros/index.vue").then(m => m.default || m)
  },
  {
    name: indexZETROZKIcTMeta?.name ?? "producer_wizard",
    path: indexZETROZKIcTMeta?.path ?? "/producer_wizard",
    meta: indexZETROZKIcTMeta || {},
    alias: indexZETROZKIcTMeta?.alias || [],
    redirect: indexZETROZKIcTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/producer_wizard/index.vue").then(m => m.default || m)
  },
  {
    name: new3aF4rqkogXMeta?.name ?? "producer_wizard-new",
    path: new3aF4rqkogXMeta?.path ?? "/producer_wizard/new",
    meta: new3aF4rqkogXMeta || {},
    alias: new3aF4rqkogXMeta?.alias || [],
    redirect: new3aF4rqkogXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/producer_wizard/new.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93OsOWxVXvk9Meta?.name ?? "producer_wizard-success-slug",
    path: _91slug_93OsOWxVXvk9Meta?.path ?? "/producer_wizard/success/:slug()",
    meta: _91slug_93OsOWxVXvk9Meta || {},
    alias: _91slug_93OsOWxVXvk9Meta?.alias || [],
    redirect: _91slug_93OsOWxVXvk9Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/producer_wizard/success/[slug].vue").then(m => m.default || m)
  },
  {
    name: resgatarTT0vypIzdFMeta?.name ?? "resgatar",
    path: resgatarTT0vypIzdFMeta?.path ?? "/resgatar",
    meta: resgatarTT0vypIzdFMeta || {},
    alias: resgatarTT0vypIzdFMeta?.alias || [],
    redirect: resgatarTT0vypIzdFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/resgatar.vue").then(m => m.default || m)
  },
  {
    name: indexRYqrGfppHXMeta?.name ?? "u-slug-productSlug",
    path: indexRYqrGfppHXMeta?.path ?? "/u/:slug()/:productSlug()",
    meta: indexRYqrGfppHXMeta || {},
    alias: indexRYqrGfppHXMeta?.alias || [],
    redirect: indexRYqrGfppHXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/u/[slug]/[productSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexVeV4FOmvW8Meta?.name ?? "u-slug",
    path: indexVeV4FOmvW8Meta?.path ?? "/u/:slug()",
    meta: indexVeV4FOmvW8Meta || {},
    alias: indexVeV4FOmvW8Meta?.alias || [],
    redirect: indexVeV4FOmvW8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/u/[slug]/index.vue").then(m => m.default || m)
  }
]