import axios from 'axios'
// import store from '../stores'
// import router from '../router'
// import Vue from 'vue'

// import router from nuxt

import { useMainStore } from '@/store/root.js'
import { useUserStore } from '@/store/user.js'

import { toast } from 'vue3-toastify';
// create a new axios instance
const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL || 'http://localhost:3000/',
});

// import { useNProgress } from '@vueuse/integrations/useNProgress'

instance.interceptors.request.use(async config => {
  const store = useMainStore()
  await store.requestLoading(true)

  if(!import.meta.env.SSR) {
    // useNProgress().start()
  }

  const storeUser = useUserStore()
  if(storeUser.loggedIn) {
    config.headers.Authorization = storeUser.token
  }

  return config
}, async (error) => {
  const store = useMainStore()
  await store.requestLoading(false)
  if(!import.meta.env.SSR) {
    // useNProgress().done()
  }
  return Promise.reject(error)
})

instance.interceptors.response.use(async response => {
  const store = useMainStore()
  await store.requestLoading(false)
  if(!import.meta.env.SSR) {
    // useNProgress().done()
  }
  return response
}, async function (error) {
  try {
    const store = useMainStore()
    await store.requestLoading(false)

    if(!import.meta.env.SSR) {
      // useNProgress().done()
    }

    // console.log(window.location.pathname)
    if(error.response?.status === 401 &&
       window.location.pathname != '/entrar' &&
        !window.location.pathname.toString().startsWith('/u/') &&
        !window.location.pathname.toString().startsWith('/checkout/')) {
      // pode deslogar o cara
      // e redirecionar por ex
      // router.push('/entrar') # FIXME NUXT 3
      toast('login expirado, entre em sua conta novamente')
      const userStore = useUserStore()
      await userStore.logout()
      // posthog.reset()
      return Promise.reject(error)
    }
    // console.log('aqui')
    // return error
    return Promise.reject(error)
  } catch (error2) {
    return Promise.reject(error2)
  }
})


export default instance
