import instance from './base.service';

class UserService {
  async login(user) {
    return instance
      .post(`users/sign_in.json`, { user: {
        email: user.email,
        password: user.password
      }})
      .then((response) => {
        return response;
      });
  }
  
  async loginOtp(email, loginCode) {
    return instance
      .post(`users/sign_in_otp.json`, { user: {
        email: email,
        otp_code: loginCode
      }})
      .then((response) => {
        return response;
      });
  }

  register(user) {
    return instance.post('users/register.json', { user: user });
  }

  logout () {
    return instance.delete('users/sign_out.json')
  }

  account () {
    return instance.get('users/edit.json')
  }

  updateAccount(params) {
    return instance.post('users/account.json', { user: params });
  }
  
  updateDocuments (params) {
    return instance.post('users/documents.json', { user: params });
  }

  zipLookup (zipcode) {
    return instance.get(`https://viacep.com.br/ws/${zipcode}/json/`)
  }

  zipLookupApiCep (zipcode) {
    return instance.get('https://ws.apicep.com/cep.json?code=' + zipcode)
  }

  // update(params) {
  //   return instance.put(`api/users/update_profile.json`, params)
  // }

  // delete() {
  //   return instance.delete(`api/users/destroy_profile.json`)
  // }

  // confirm(token) {
  //   return instance.put(`${API_URL}confirmations/${token.token}.json`, {}).then(response => {
  //     return response.data;
  //   })
  // }
}

export default new UserService();